<template>
    
        <div class="container">
            <div class="row wrapper_flex">
                <div class="termsdesc">
                    <div>
                        <h3>{{ termsTitle }}</h3>
                        <div v-html="termsDescription"></div> <!-- Use v-html to render HTML content -->
                        
                    </div>
                </div>
            </div>
        </div>
    
</template>
<script>
export default {
    data() {
        return {
            termsTitle: '', // Title for the terms popup
            termsDescription: '', // Description for the terms popup
           // isTermsPopupOpen: false, // Flag to control terms popup visibility
        };
    },
    mounted() {
        // this.fetchEventData();
        this.fetchTerms();
    },
      /**
       * 
       */
    methods: {
        fetchTerms() {
            fetch('https://lakalle937.com/lakalleradio/api/terms')
                .then(response => response.json())
                .then(data => {
                    if (data.status === 200) {
                        const term = data.data[0]; // Get the first term object
                        this.termsTitle = term.title;
                        this.termsDescription = term.description;
                        //this.isTermsPopupOpen = true; // Show the terms popup
                    } else {
                        console.error('Error fetching terms:', data.message);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }
}
</script>