import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyBEurA1FN96gUFCF7WIqxeF5_NLMNNmvws",
    authDomain: "lakalleradio-33b44.firebaseapp.com",
    databaseURL: "https://lakalleradio-33b44-default-rtdb.firebaseio.com",
    projectId: "lakalleradio-33b44",
    storageBucket: "lakalleradio-33b44.appspot.com",
    messagingSenderId: "819561823714",
    appId: "1:819561823714:web:a9201967cbd67d29a48dbb"
  };

firebase.initializeApp(config);

export default firebase.database();