<template> 
	<section class="Top-song bg-grey " >
		<loader v-if="loading"></loader>
		<div class="same-space pt-0 mt-0 pb-5 ">
			
			<div class="container"  style="margin-top:50px">
				<div class="main-heading">
					<div class="pattern">
						<img src="assets/images/Design-Square-Ellipse.png">
					</div>
					<h1 class="title-section pt-0 pb-5">
						Top Billboards
					</h1>
				</div>
				<div class="gride-container">
					<div class="gride-box" v-for="value in topSongs" :key="value.songcategoryitem_id">
						<div class="loader-static" @click="playSong(value, topSongs)">
							<div class="loader-dynamic">
								<img :src="value.song_image"  @error="$event.target.src='assets/images/placeholder.png'">
							</div>
							<img src="assets/images/Square.png" class="stactic-img">
							<div class="hover-play">
								<div class="play">
									<img src="assets/images/play-icon.png">
								</div>
								<div class="list-icon-drop">
									<div class="dropdown">
										<a class="list-icon" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<img src="assets/images/menu.png">
										</a>

										<div class="dropdown-content" aria-labelledby="dropdownMenuLink">
											<a class="dropdown-item" href="#" data-toggle="modal" :data-target="'.Addplaylist'+value.song_id">Add Playlist</a>
											
											<a class="dropdown-item" href="javascript:void();" v-if="value.favourites_status === false" @click="AddRemoveFromFavourites(user.user_id, value.song_id)">Add Favorites</a>
											<a class="dropdown-item" href="javascript:void();" v-if="value.favourites_status === true" @click="AddRemoveFromFavourites(user.user_id, value.song_id)">Remove</a>
										</div>
									</div>
								</div>
							</div>
							<!--  Add playlist-modal  -->
							<div class="modal fade play-list-modal Modal-key Green-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" :id="'addplaylistmodel'+value.song_id" :class="'Addplaylist'+value.song_id">
								<div class="modal-dialog" role="document">
									<div class="modal-content">
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
										<div class="modal-icon">
											<i class="fas fa-music"></i>
										</div>
										<div class="modal-body">

											<div class="modal-wrapper">
												<div class="modal-title">
													<h3>Create Playlist</h3>
													<div class="modal-form">
														<form @submit.prevent="CreatePlaylist(value.song_id)">
															<div class="from-group">
																<input type="text" class="from-control" placeholder="Enter A Playlist Name" v-model="name">
																<div class="form-submit">
																	<button type="submit" class="btn btn-lg btn-modal btn-green-modal">
																	Submit
																</button>
																</div>
															</div>
														</form>

														<div class="playlist-box mt-4">
															<ul>
																<li v-for="playlist_value in playlist_listing" :key="playlist_value.playlist_id">
																	<a href="javascript:void();" @click="CreatePlaylistSong(user.user_id, playlist_value.playlist_id, value.song_id)">{{ playlist_value.name }}</a>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- end modal -->
						</div>
						<div class="gride-content">
							<h6>{{ value.song_name }}</h6>
							<p>{{ value.song_artist }}</p>
						</div>
					</div>
				</div>
			</div>

			<featuredslider :FeaturedSlider="featured_slider"/>
			
			<div class="container" style="margin-top:50px">
				<div class="main-heading">
					
					<h1 class="title-section pt-0 pb-5">
						Daily Shows And More
					</h1>
				</div>
				<div class="gride-container">
					<div class="gride-box" v-for="value in program" :key="value.event_id">
						<div class="loader-static">
							<div class="loader-dynamic">
								<img :src="value.event_image"  @error="$event.target.src='assets/images/placeholder.png'"  @click="openpop(value.event_id)">
							</div>
							<img src="assets/images/trans300.jpeg" class="stactic-img">
						</div>
						<div class="gride-content"  @click="openpop(value.event_id)" style="background:#100c08; height:100%;">
							<h6 style="color:white;"><b>{{ value.event_name }}</b></h6>
							<p  style="color:white;"> {{ value.event_days }} </p>
							<span style="color: white;font-size: 12px;">{{ value.event_start_time }}  </span>
							<span style="color: white;font-size: 12px; margin-left:4px;"> -  {{ value.event_end_time }}</span>
							
						</div>
						<div class="model-open" :id="'popbox_'+value.event_id" style="display:none;" >
							<span style="cursor:pointer" class="clsclick" @click="closepop()">X</span>
							<div class="img_mdl">
								<img :src="value.event_image">
							</div>
							<div class="desc_title">
									
									<h6 style="color:white;"><b>{{ value.event_name }}</b></h6>
									<p  style="color:white;"> {{ value.event_days }} </p>
									<span style="color: white;font-size: 12px;">{{ value.event_start_time }}  </span><span style="color: white;font-size: 12px; margin-left:4px;"> -  {{ value.event_end_time }}</span>		
									<p class="ptx">{{value.event_description}}</p>	
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	<div class="overlay_black" id="ov_black"></div>	
	</section>

</template>

<script>
	import {HTTP} from '../../_helper/http-constants.js';
	import { EventEmmiter } from "../../eventEmmiter.js";
	import featuredslider from './featuredSlider.vue';
	export default {
		name: "TopSongs",
		props: ['topSongs'],
		components: {
			featuredslider,
		},
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				program: null,
				playlist_listing: null,
				name: '',
				loading: false,
				home_slider: null,
				featured_slider:null,
				music_category: null,
				top_songs: null,
				daily_shows: null,
			}
		},
		methods: {
			getPlaylist() { 
				this.loading = true;
				HTTP.post('/playlist',{
					user_id: this.user.user_id
				}).then(res => {
					if(res.data.status === 200){
						this.playlist_listing = res.data.data;
						this.loading = false;
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				})
				.catch(err => {
					this.loading = false;
					console.log(err)
				});

				HTTP.get(`/program`).then(res => {
					if (res.data.status === 200) {
						this.program = res.data.data;
						//this.current_page = res.data.current_page;
						//this.pages = res.data.last_page;
						this.loading = false;
					} else {
						this.$swal.fire({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
						icon: 'warning',
						title: res.data.message
						});
					}
				});
			},
			CreatePlaylist(song_id = '') {
				this.loading = true;
				HTTP.post('/playlist/add', {
					user_id: this.user.user_id,
					name: this.name
				}).then(res => {
					if(res.data.status === 200){
						this.getPlaylist();
						this.CreatePlaylistSong(this.user.user_id, res.data.playlist_id, song_id);
						this.loading = false;
					}else{
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
						document.body.classList.remove('modal-open');
						this.loading = false;
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
			openpop(popid){
				//alert(popid);
				document.getElementById("ov_black").style.display = "block";
				document.getElementById("popbox_"+popid).style.display = "block";
			},
			closepop(){
				//alert("in");
				//document.getElementsByClassName("model-open").style.display = 'none';
				var elems = document.getElementsByClassName('model-open');
					for (var i=0;i<elems.length;i+=1){
					elems[i].style.display = 'none';
					}
				document.getElementById("ov_black").style.display = "none";	
			},
			CreatePlaylistSong(user_id = '', playlist_id = '', song_id = '') {
				this.loading = true;
				HTTP.post('/playlistsong/add', {
					user_id: user_id,
					playlist_id: playlist_id,
					song_id: song_id
				}).then(res => {
					if(res.data.status === 200){
						let backdrop = document.getElementsByClassName("modal-backdrop");
						while(backdrop.length > 0){
							backdrop[0].parentNode.removeChild(backdrop[0]);
						}
						// close the modal
						document.getElementById("addplaylistmodel"+song_id).style.display = "none";
						document.getElementById("addplaylistmodel"+song_id).classList.remove("show");
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'success',
							title: res.data.message
						});
						document.body.classList.remove('modal-open');
						this.$router.push({name: 'Home'});
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
			AddRemoveFromFavourites(user_id = '', song_id = ''){
				this.loading = true;
				HTTP.post('/favourites/add_remove',{
					user_id: user_id,
					song_id: song_id
				}).then(res => {
					if(res.data.status === 200){
						this.loading = false;
						//this.getCategorySongs();
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'success',
							title: res.data.message
						});
						//this.$router.push({name: 'Favourites'});
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
			getHomeData(){
				this.loading = true;
				HTTP.get('/cms').then(res => {
					if(res.data.status === 200){
						this.featured_slider = res.data.homeslider_datatwo;
						// this.music_category = res.data.songcategory_data;
						// this.top_songs= res.data.song_data;
						// this.sponsor_banner = res.data.sponsorbanner_data;
						this.loading = false;
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},		
			playSong(song, AllSong) {
				if (this.currentPlaying == song.song_id) {
					// this.eventEmmiterService.pauseSong(true);
					// this.currentPlaying = 0;
					return true;
				} else {
					this.currentPlaying = song.song_id;
					let songPlayList = [];
					songPlayList.push(song);

					if (AllSong && AllSong.length > 0) {
						const removeDuplicatesongLists = AllSong.filter((songObj) => {
							return songObj.song_id != song.song_id;
						});
						songPlayList = songPlayList.concat(removeDuplicatesongLists);
						const finalPlayList = JSON.parse(JSON.stringify(this.preparePlayListArry(songPlayList)));
						this.changePlaySong(finalPlayList);
					} else {
						const finalPlayList = JSON.parse(JSON.stringify(this.preparePlayListArry(songPlayList)));
						this.changePlaySong(finalPlayList);
					}
				}
			},
			changePlaySong(songDetailObj) {
				const removeDuplicatesong = songDetailObj.filter((songObj) => {
					return songObj.song_id != this.currentPlaying;
				});
				this.emitCurrentSongId(removeDuplicatesong[0].id);
				this.emitSongChange(removeDuplicatesong);
			},
			emitCurrentSongId(id) {
				EventEmmiter.$emit("currentSongId", id);
			},
			emitSongChange(songObject) {
				EventEmmiter.$emit("currentSongObject", songObject);
			},
			preparePlayListArry(songListArry) {
				let songListResArry = [];
				if (songListArry && songListArry.length > 0) {
					songListArry.forEach((songObjN) => {
						const tempListArry = [];
						const newSongObj = {};
						newSongObj.id           = songObjN.song_id;
						newSongObj.title        = songObjN.song_name;
						newSongObj.file         = (songObjN.song ? songObjN.song : ' ');
						newSongObj.poster       = songObjN.song_image;
						newSongObj.description  = '';
						tempListArry.push(newSongObj);
						songListResArry = songListResArry.concat([newSongObj]);
					});
				}
				return songListResArry;
			},
			
		},
		mounted() {
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getPlaylist();
			this.getHomeData();
		}
	}
</script>