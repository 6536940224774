<template>
	<div class="liveradio top-spacing" style="display: flex;justify-content: center; min-height: 90vh;">
		<loader v-if="loading"></loader>
		<section class="live-radio-wrapper">
			
				<div class="radion-div">
					<div class="container radio-live" style="max-width: 500px;margin: 10px auto;border-radius: 20px;background: #151515;">
							
								<!-- Image slider for multiple images with dots and autoplay -->
					<!-- <vue-slick-carousel 
						v-if="liveradio && liveradio[0].song_images" 
						:autoplay="true" 
						:arrows="true" 
						:dots="true"
						autoplay-speed="3000"> -->
						
						<!-- Loop through the song images -->
						<!-- <div v-for="(image, index) in songImageArray" :key="index">
							<img :src="image" alt="Song Image" class="img-fluid">
						</div>
					</vue-slick-carousel> -->
					<div class="image-slider">
						<!-- Display current image with fade effect -->
						<img v-for="(image, index) in songImageArray" :key="index" :src="image" 
							:class="{ 'active': index === currentImageIndex }" alt="song image" />

						<!-- Dots for navigation -->
						<!-- <div class="slider-dots">
							<span v-for="(image, index) in songImageArray" :key="index" 
								:class="{'active-dot': index === currentImageIndex}" 
								@click="changeImage(index)">
							</span>
						</div> -->
					</div>
							
							<div class="push-play" style="position: relative;">
								<a v-if="!isPlaying" ref="playButton" class="play" href="javascript:void();" @click="playRadio(liveradio[0], liveradio)">
									<img src="assets/images/radio-play.png">
								</a>
								<a v-if="isPlaying" class="pause" href="javascript:void();" @click="pauseRadio()">
									<img src="assets/images/radio-pause.png">
								</a>
								<div class="red-dot"></div>
							</div>
							<div class="radio-title"><img src="https://lakalle937.com/lakalleradio/assets/img/logo.png"/></div>
						
					</div>        
				</div>
		</section>
	</div>
</template>
<script>
	import {HTTP} from '../../_helper/http-constants.js';
	import { EventEmmiter } from "../../eventEmmiter.js";
	//import VueSlickCarousel from 'vue-slick-carousel';
	export default {
		name: "LiveRadio",
		components: {
			//VueSlickCarousel
		},
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				liveradio: null,
				loading: false,
				songImageArray: [],  // New array for images
				currentImageIndex: 0,
				isPlaying: false,
				met:"test",
				device_type: 'web',
				token: '',
				//liveradio: null,
			}
		},
		methods: {
			appUsers(){
				this.loading = true;
				if(!this.user){
					HTTP.post('/appusers', {
						device_type: this.device_type,
						token: (this.token != null || this.token != "") ? this.token : null
					}).then(res => {
						if(res.data.status === 200){
							window.localStorage.removeItem('user');
							// set user in localStorage
							const user = {
								user_id: res.data.user_id,
								device_type: res.data.device_type,
								token: res.data.token
							};
							window.localStorage.setItem('user', JSON.stringify(user));
							this.loading = false;
							//window.location.replace('/');
							this.loading = false;
						}else{
							this.loading = false;
							this.$swal.fire({
								toast: true,
								position: 'top-end',
								showConfirmButton: false,
								timer: 3000,
								timerProgressBar: true,
								icon: 'warning',
								title: res.data.message
							});
						}
					}).catch(err => {
						console.log(err);
						this.loading = false;
					});
				}else{
				//	this.getHomeData();
					this.loading = false;
				}
			},
			getLiveRadio() {
				this.loading = true;
				HTTP.get('/liveradio').then(res => {
					if(res.data.status === 200){
						this.liveradio = res.data.data;
						// Check if liveradio data exists and if song_image is defined
						// Directly assign the song_image array to songImageArray
						if (this.liveradio && this.liveradio[0] && Array.isArray(this.liveradio[0].song_image)) {
							this.songImageArray = this.liveradio[0].song_image;
						} else {
							this.songImageArray = [];
						}
						//alert("in2");
						//console.log("songImageArray",this.songImageArray);
						
						this.loading = false;
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			},
			changeImage(index) {
				this.currentImageIndex = index;
			},

			// Autoplay functionality for the slider
			startAutoplay() {
				setInterval(() => {
					this.currentImageIndex = (this.currentImageIndex + 1) % this.songImageArray.length;
				}, 10000); // Change every 3 seconds
			},
			playRadio(song, AllSong) {
				// console.log("array",song);
				//alert("deep");
				if (this.currentPlaying == song.liveradio_id) {
					// this.eventEmmiterService.pauseSong(true);
					// this.currentPlaying = 0;
					console.log("array",song);
					return true;
				} else {
					this.currentPlaying = song.liveradio_id;
					let songPlayList = [];
					console.log("array2",song);
					songPlayList.push(song);

					if (AllSong && AllSong.length > 0) {
						const removeDuplicatesongLists = AllSong.filter((songObj) => {
							return songObj.live_radio_id != song.liveradio_id;
						});
						songPlayList = songPlayList.concat(removeDuplicatesongLists);
						const finalPlayList = JSON.parse(JSON.stringify(this.preparePlayListArry(songPlayList)));
						this.changePlaySong(finalPlayList);
					} else {
						const finalPlayList = JSON.parse(JSON.stringify(this.preparePlayListArry(songPlayList)));
						this.changePlaySong(finalPlayList);
					}
					this.isPlaying = true; 
					
				}
			},
			pauseRadio(){
				//onPauseAudio();
				this.isPlaying = false; 
				window.parent.onPauseAudio();
			},
			changePlaySong(songDetailObj) {
				const removeDuplicatesong = songDetailObj.filter((songObj) => {
					return songObj.live_radio_id != this.currentPlaying;
				});
				this.emitCurrentSongId(removeDuplicatesong[0].id);
				this.emitSongChange(removeDuplicatesong);
			},
			emitCurrentSongId(id) {
				EventEmmiter.$emit("currentSongId", id);
			},
			emitSongChange(songObject) {
				EventEmmiter.$emit("currentSongObject", songObject);
			},
			preparePlayListArry(songListArry) {
				let songListResArry = [];
				if (songListArry && songListArry.length > 0) {
					songListArry.forEach((songObjN) => {
						const tempListArry = [];
						const newSongObj = {};
						newSongObj.id           = songObjN.live_radio_id;
						newSongObj.title        = songObjN.song_name;
						newSongObj.file         = (songObjN.song ? songObjN.song : ' ');
						newSongObj.poster       = songObjN.song_image[0];
						newSongObj.description  = '';
						tempListArry.push(newSongObj);
						songListResArry = songListResArry.concat([newSongObj]);
					});
				}
				return songListResArry;
			}

			
		},
		watch: {
		liveradio(newVal) {
			// Trigger playRadio when liveradio is loaded and shouldAutoPlay is set
				if (newVal && newVal.length > 0 && this.shouldAutoPlay) {
					this.playRadio(this.liveradio[0], this.liveradio);
				}
			},
		},
		beforeRouteEnter(to, from, next) {
				
				next(vm => {
				// Check if the user came from the splash video route
					if (from.name === 'splashvideo') {
						// If the play button exists, trigger the click event
						//if (vm.$refs.playButton) {
							
							//vm.$refs.playButton.click();
							
							vm.shouldAutoPlay = true;
							//alert("in1");
						//}
					}
				});
		},
		
		mounted() {
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.getLiveRadio();
			// Start autoplay once the component is mounted
			this.startAutoplay();
			this.appUsers();
			// alert(this.shouldGetLiveRadio);
			// if (this.shouldGetLiveRadio) {
			// 	//alert("inw");
			// 	this.playRadio(this.liveradio[0], this.liveradio);

			// }
				
		}
	}
</script>

<style>
.image-slider {
    position: relative;
    width: 407px;
    height: 407px;
    margin: 20px auto;
    border-radius: 20px;
    overflow: hidden;
    background: rgba(58, 51, 51, 0.7); /* Smoke black background */
    display: flex;
    align-items: center;
    justify-content: center;
	border: 0.6px solid;
}

.image-slider img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 20px;
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out; /* Smooth fade-in and fade-out effect */
}

.image-slider img.active {
    opacity: 1; /* Only the active image is visible */
}

.slider-dots {
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.slider-dots span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: gray;
    border-radius: 50%;
    cursor: pointer;
}

.slider-dots .active-dot {
    background-color: white;
}


@media only screen and (min-width: 768px){
    /* Styles for iPads */
    .image-slider {
      position: relative;
      width: 219px;
      height: 219px;
    }

    .radio-live{
        max-width: 350px !important;
   
    }
  }
</style>
<style>
    .red-dot{
		width: 20px;
		height: 20px;
		background-color: red;
		border-radius: 50%;
		animation: fadeInOut 2s infinite;
		position: absolute;
		top: 24px;
		right: -44px;
    }

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  </style>